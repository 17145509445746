<template>
  <div>
     <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户单号">
          <el-input v-model="dataForm.customerSelfNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单车型">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.vehicleType"
                     size="mini" style="width: 100%" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.vehicle_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="请输入车牌号" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户联系人">
          <el-input v-model="dataForm.customerContactName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
<!--        <el-form-item label="车次号">-->
<!--          <el-input v-model="dataForm.planNo" placeholder="支持模糊查询" clearable></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="订单类型" prop="orderType">
          <el-select class="item-choose" v-model="dataForm.orderType" style="width: 100%;" @change="getDataList" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable placeholder="请选择">
            <el-option label="已收款" value="1"></el-option>
            <el-option label="未收款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款人">
          <el-input v-model="dataForm.writeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="共赢车辆" prop="allWinVehicle">
          <el-select class="item-choose" v-model="dataForm.allWinVehicle" style="width: 100%;" filterable clearable placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:receivable:export')" @click="downLoad">导出</el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="openWriteOffPopup(1)" :disabled="!selectListArr.length">已收款核销</el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="batchAudit(2)" :disabled="!selectListArr.length">反核销</el-button>
        </el-form-item>
         <el-form-item style="text-align: right;float: right;">
          <div class="operationList" v-if="newAuth('cost:receivable:gen')">
             <el-button size="small" @click="openGenBillPopup(1)" type="danger">生成日账单</el-button>
             <el-button size="small" @click="openGenBillPopup(2)" type="danger">生成周账单</el-button>
             <el-button size="small" @click="openGenBillPopup(3)" type="danger">生成月账单</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" border size="small"
                :height="tableHeight"
                :span-method="arraySpanMethod"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                @selection-change="handleSelectionChange"
                v-loading="dataListLoading" style="width: 100%;">
        <el-table-column type="selection" width="35" fixed="left" align="center" ></el-table-column>
        <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true"  fixed="left">
          <template slot-scope="scope">
            {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
          </template>
        </el-table-column>
        <el-table-column label="客户名称" prop="orderInfo.customerName" width="70" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
<!--        <el-table-column label="客户业务员" prop="orderInfo.customerBusinessName" width="80" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column label="客户联系人"  prop="orderInfo.customerContactName" width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单类型" :formatter="orderType"  width="60"  :show-overflow-tooltip="true" prop="orderInfo.orderType">
        </el-table-column>
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单车型" :formatter="dictConvertVehicle" width="80" :show-overflow-tooltip="true" prop="orderInfo.vehicleType">
        </el-table-column>
<!--        <el-table-column label="指派车型" prop="vehiclePlan.vehicleTypeName" >-->
<!--        </el-table-column>-->
        <el-table-column label="司机车牌"  prop="vehiclePlan.vehiclePlate" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货方" prop="factoryName" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="起点" prop="route" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="卸货方" prop="giveAddressInfo.factoryName" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="终点" prop="giveAddressInfo.route" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="费用小计" prop="costInfo.totalMoney" :formatter="getNumValue" width="80"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="运费" prop="costInfo.freightFee" :formatter="getNumValue" width="80"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报关/入仓费" prop="costInfo.entranceFee" :formatter="getNumValue" width="75"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="高速费" prop="costInfo.highFee" :formatter="getNumValue" width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="过磅费" prop="costInfo.weighFee" :formatter="getNumValue" width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="装卸费" prop="costInfo.loadFee" :formatter="getNumValue" width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="押夜/超时费" prop="costInfo.overtimeFee" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货费" prop="costInfo.takeFee" :formatter="getNumValue" width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="停车费" prop="costInfo.parkingFee" :formatter="getNumValue"  width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="加点费" prop="costInfo.addFee" :formatter="getNumValue"  width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="其他费用" prop="costInfo.otherFee" :formatter="getNumValue"  width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="其他费用备注"  prop="otherFeeRemark" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.costInfo.otherFeeRemark }}
          </template>
        </el-table-column>
        <el-table-column label="预收费用" prop="costInfo.receivedFee" :formatter="getNumValue"  width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="预收备注" prop="costInfo.receivedRemark" :formatter="getNumValue"  width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum" min-width="60" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="体积" prop="orderGoods.volume" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="重量" prop="orderGoods.weight" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机姓名"  prop="vehiclePlan.driverName" width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机电话"  prop="vehiclePlan.driverPhone" width="90"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户单号"  prop="orderInfo.customerSelfNo" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户联系电话"  prop="orderInfo.customerContactPhone" width="90"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="下单时间" prop="orderInfo.commitTime" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="下单人" prop="orderInfo.commitName" width="50" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单号" prop="orderInfo.orderNo" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="车次号" prop="vehiclePlan.planNo" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收款人" prop="costInfo.writeName" width="50" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收款时间" prop="costInfo.writeDate" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="账单状态" prop="genStatus" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.costInfo.genStatus == 2" size="small" type="success">已生成</el-tag>
            <el-tag v-else size="small" type="danger">未生成</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="收款状态" prop="writeStatus" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.costInfo.writeStatus === 1" size="small" type="success">已收款</el-tag>
            <el-tag v-else size="small" type="danger">未收款</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('cost:receivable:find')" type="text" size="small" @click="operatopnList('cost', 'view', scope.row)">详情</el-button>
              <el-button v-if="newAuth('cost:receivable:update')" type="text" size="small" @click="operatopnList('cost', 'edit', scope.row)">费用修改</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <audit-pass ref="pass" @refreshDataList="getDataList"></audit-pass>
    <cancel-audit ref="cancel" @refreshDataList="getDataList"></cancel-audit>
    <cost-modification ref="cost" @refreshDataList="getDataList"></cost-modification>
    <!-- 生成账单弹框 -->
    <gen-popup ref="genBill" @refreshDataList="getDataList"></gen-popup>
    <!-- 核销弹框 -->
    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>
  </div>
</template>

<script>
import auditPass from './detail/auditPass-popup'
import cancelAudit from './detail/cancelAudit-popup'
import costModification from './detail/chargeablePool-popup'
import genPopup from './detail/gen-bill-popup'
import writeOffPopup from './detail/write-off-popup'
import qs from 'qs'
export default {
  data () {
    return {
      dataForm: {
        orderInfo: {},
        costType: null,
        status: 2,
        type: 1,
        takeGoodsTime: null,
        planNo: null,
        writeStatus: null,
        writeName: null,
        orderType: null,
        vehicleType: null,
        vehiclePlate: null,
        inWarehouseNo: null,
        customerSelfNo: null,
        customerContactName: null,
        customerName: null,
        driverName: null,
        allWinVehicle: null,
        customerIdList: []
      },
      tableHeight: '65vh',
      selectListArr: [],
      costType: 1,
      listType: 1,
      tabActive: '1',
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      genBillVisible: false,
      writeOffVisible: false,
      detailVisible: false
    }
  },
  components: {
    auditPass,
    cancelAudit,
    costModification,
    genPopup,
    writeOffPopup
  },
  created () {
    if (this.newAuth('cost:receivable:customer')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
    this.setDefaultDate()
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    }
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 多选
    handleSelectionChange (rows) {
      this.selectListArr = rows
    },
    batchAudit (writeStatus) {
      let ids = []
      this.selectListArr.forEach(item => {
        if (item.costInfo.writeStatus !== writeStatus) {
          ids.push(item.costInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/costInfo/orderCost/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    openGenBillPopup (billType) {
      this.genBillVisible = true
      this.$refs.genBill.init(billType, this.dataForm.type)
    },
    openWriteOffPopup (writeStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectListArr.forEach(item => {
        if (item.costInfo.writeStatus !== writeStatus) {
          ids.push(item.costInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      this.$refs.writeOff.init(this.costType, writeStatus, ids)
    },
    /**
     * 点击表格变色end
     */
    operatopnList (type, todo, row) {
      this.$refs.cost.init(type, todo, row, 1)
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/costExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': this.listType,
          'costType': this.costType,
          'status': this.dataForm.status,
          'writeStatus': this.dataForm.writeStatus,
          'orderType': this.dataForm.orderType,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'customerContactName': this.dataForm.customerContactName,
          'customerName': this.dataForm.customerName,
          'vehicleType': this.dataForm.vehicleType,
          'vehiclePlan.planNo': this.dataForm.planNo,
          'vehiclePlan.driverName': this.dataForm.driverName,
          'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
          'vehiclePlan.allWinVehicle': this.dataForm.allWinVehicle
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '深圳市瑞通达物流有限公司（应收费用表）.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.orderInfo.id === preId) {
            return ''
          }
          preId = item.orderInfo.id
          if (column.property && column.property.indexOf('costInfo.') > -1) {
            let val = column.property.split('.')
            return this.getValue(Number(item[val[0]][val[1]]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (costInfo) {
      for (let c in costInfo) {
        costInfo[c] = this.getValue(costInfo[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        costInfo.totalMoney,
        costInfo.freightFee,
        costInfo.entranceFee,
        costInfo.highFee,
        costInfo.weighFee,
        costInfo.loadFee,
        costInfo.overtimeFee,
        costInfo.takeFee,
        costInfo.parkingFee,
        costInfo.addFee,
        costInfo.otherFee, '', costInfo.receivedFee, '', '', '', '', '', '', '', '', '', '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // var itemTr = document.createElement('tr')
      // itemTr.innerHTML = document.querySelector('.el-table__footer-wrapper table tbody tr').innerHTML
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'status': this.dataForm.status,
        'writeStatus': this.dataForm.writeStatus,
        'writeName': this.dataForm.writeName,
        'costType': this.costType,
        'orderType': this.dataForm.orderType,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'customerContactName': this.dataForm.customerContactName,
        'customerName': this.dataForm.customerName,
        'vehicleType': this.dataForm.vehicleType,
        'vehiclePlan.planNo': this.dataForm.planNo,
        'vehiclePlan.driverName': this.dataForm.driverName,
        'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
        'vehiclePlan.allWinVehicle': this.dataForm.allWinVehicle,
        'customerIdList': this.dataForm.customerIdList,
        'customerSelfNo': this.dataForm.customerSelfNo
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/costInfo/findList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list)
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.costInfo || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
        this.tableHeight = '67vh'
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$nextTick(() => {
        this.$refs.detail.init(row, todo)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.simpleName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/company/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less">
div#pane-costManagement-chargeablePool {
}
</style>
